<template>
	<div>
		<div class="sa-hero-banner">
			<img src="../../../public/img/labtech-nurse-bg.svg" alt="image" />
			<header class="sa-header sa-header-trans">
				<router-link to="/"> <i class="icon-logo-symbol sa-home-logo"></i></router-link>
				<p>Welcome to Sanar!</p>
				<h1>{{$t("provider_login")}}</h1>
			</header>
		</div>
		<otp-verify />
	</div>
</template>
<script>
import OTPVerifyVue from '../../components/home/OTPVerify.vue';

export default {
	components: {
		'otp-verify': OTPVerifyVue
	},
	data() {
		return {
			text:this.$store.getters['common/getText'],
		};
	},
	computed: {
		// spName: function(){
		// 	return ((this.$route.name=='provider_login')? this.$t("provider_login"):this.$t("provider_login"))
		// },
		// bnrImgPath: function(){
		// 	return ((this.$route.name=='nurse')? 'labtech-nurse-bg.svg':'labtech-nurse-bg.svg')
		// }
	},
	methods: {
		toggleBodyClass: function(addRemoveClass, className) {
			const el = document.body;
			if (addRemoveClass === "addClass") {
				el.classList.add(className);
			} else {
				el.classList.remove(className);
			}
		},
	},
	mounted() {
		this.toggleBodyClass("addClass", "sa-home-bg");
	},
	unmounted() {
		this.toggleBodyClass("removeClass", "sa-home-bg");
	}
};
</script>
